var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "popup_wrap doc_req_pop" }, [
    _c(
      "button",
      {
        staticClass: "layer_close",
        on: {
          click: function ($event) {
            return _vm.$emit("close")
          },
        },
      },
      [_vm._v("close")]
    ),
    _c(
      "form",
      {
        attrs: { id: "frm_req_pop" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "popup_cont cancel_cont_area" },
          [
            _c("h1", { staticClass: "page_title" }, [
              _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.001")) + " "),
              _c(
                "span",
                { staticClass: "position_relative screen-to-pdf-hide" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon help",
                      on: {
                        mouseover: function ($event) {
                          return _vm.showToolTip()
                        },
                        mouseleave: function ($event) {
                          return _vm.showToolTip()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.027")))]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.toolTipActive,
                          expression: "toolTipActive",
                        },
                      ],
                      staticClass: "tooltip_wrap short position_absolute",
                      staticStyle: { width: "450px", left: "0", top: "28px" },
                    },
                    [
                      _c("div", { staticClass: "cont" }, [
                        _c("p", { staticClass: "title" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.001"))),
                        ]),
                        _c("ul", { staticClass: "bul_list_sm t2" }, [
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.028")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.029")) + " "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.030")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.031")) + " "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.032")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.033")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.034")) + " "
                            ),
                          ]),
                          _c("li", [
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.035")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.036")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.037")) + " "
                            ),
                            _c("br"),
                            _vm._v(
                              " " + _vm._s(_vm.$t("msg.ONEX020P120.038")) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
            ]),
            _vm.delegateYn === "Y"
              ? _c("div", { staticClass: "content_box" }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.039"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$ekmtcCommon.changeDatePattern(
                                    _vm.nowDate,
                                    "."
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.002"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P120.003")) + " "
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.004"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " + _vm._s(_vm.bookingInfo.shprCstNm) + " "
                            ),
                          ]),
                          _c("div", { staticClass: "tbl_form mt5" }, [
                            _c("div", [
                              _c("span", { staticClass: "dv col_1" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicNm)),
                              ]),
                              _c("span", { staticClass: "dv col_2" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicTelNo)),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _vm._v(_vm._s(_vm.formData.reqPicEmlAddr)),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.005"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(
                              " " + _vm._s(_vm.bookingInfo.actShprCstEnm) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm.delegateYn === "Y"
              ? _c("div", { staticClass: "content_box mt10" }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(1),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.006"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _vm._v(" " + _vm._s(_vm.bookingInfo.blNo) + " "),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.007"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingInfo.polPlcNm) + " "
                              ),
                            ]),
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(
                                " " + _vm._s(_vm.bookingInfo.podPlcNm) + " "
                              ),
                            ]),
                            _c("span", { staticClass: "dv col_4" }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX010P090.009"))),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.vslNm) + " "),
                            ]),
                            _c("span", { staticClass: "dv col_3" }, [
                              _vm._v(" " + _vm._s(_vm.bookingInfo.voyNo) + " "),
                            ]),
                            _c("span", { staticClass: "dv col_4" }),
                          ]),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _c("p", [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX010P070.012"))),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "screen-to-pdf-hide" }, [
                            _c(
                              "button",
                              {
                                staticClass: "button blue lg mt10",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setCntrInfoAll()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P070.013")))]
                            ),
                          ]),
                        ]),
                        _c(
                          "td",
                          [
                            _vm._l(
                              _vm.formData.containerList,
                              function (vo, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: "cntr_" + idx,
                                    staticClass:
                                      "tbl_form div_cntr_area screen-to-pdf-hide",
                                  },
                                  [
                                    _c("span", { staticClass: "dv col_1" }, [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: vo.cntrNo,
                                              expression: "vo.cntrNo",
                                            },
                                          ],
                                          attrs: { id: "cntrNo_" + idx },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.isPrevValue($event)
                                            },
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  vo,
                                                  "cntrNo",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              function ($event) {
                                                return _vm.fnChkCntrNo(idx)
                                              },
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.formData.cntrNoList,
                                          function (option) {
                                            return _c(
                                              "option",
                                              {
                                                key: option.cntrNo,
                                                domProps: {
                                                  value: option.cntrNo,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(option.cntrNo) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                    _c("span", { staticClass: "dv col_2" }, [
                                      idx === 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "tbl_icon plus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addContainer()
                                                },
                                              },
                                            },
                                            [_vm._v(" plus ")]
                                          )
                                        : _vm._e(),
                                      idx !== 0
                                        ? _c(
                                            "button",
                                            {
                                              staticClass: "tbl_icon minus",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeContainer(
                                                    idx
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" minus ")]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            _vm._l(
                              _vm.formData.containerList,
                              function (vo, idx) {
                                return _c(
                                  "div",
                                  {
                                    key: "cntr_" + idx,
                                    staticClass:
                                      "tbl_form div_cntr_area screen-to-pdf-show",
                                  },
                                  [_c("p", [_vm._v(_vm._s(vo.cntrNo))])]
                                )
                              }
                            ),
                          ],
                          2
                        ),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P120.040")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "tbl_form screen-to-pdf-hide" },
                            [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formData.reqRsnCont,
                                      expression: "formData.reqRsnCont",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "(선적 취소 사유 및 요청사항)",
                                  },
                                  domProps: { value: _vm.formData.reqRsnCont },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.formData,
                                        "reqRsnCont",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tbl_form screen-to-pdf-show" },
                            [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c(
                                  "span",
                                  { staticClass: "screen-to-pdf-show" },
                                  [_vm._v(_vm._s(_vm.formData.reqRsnCont))]
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c("tr", [
                        _c("th", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("msg.ONEX020P120.008")) + " "
                          ),
                          _c("span", { staticClass: "txt" }, [
                            _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.009"))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "tbl_form screen-to-pdf-hide" },
                            [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.afterVslNm,
                                      expression: "afterVslNm",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Vessel Name",
                                  },
                                  domProps: { value: _vm.afterVslNm },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchSchedule()
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.afterVslNm = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.afterVoyNo,
                                      expression: "afterVoyNo",
                                    },
                                  ],
                                  attrs: {
                                    type: "text",
                                    placeholder: "Voyage No",
                                  },
                                  domProps: { value: _vm.afterVoyNo },
                                  on: {
                                    click: function ($event) {
                                      return _vm.searchSchedule()
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.afterVoyNo = $event.target.value
                                    },
                                  },
                                }),
                              ]),
                              _c("span", { staticClass: "dv font_12" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.ONEX020P120.010"))
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "tbl_form screen-to-pdf-show" },
                            [
                              _c("span", { staticClass: "dv col_3" }, [
                                _c(
                                  "span",
                                  { staticClass: "screen-to-pdf-show" },
                                  [_vm._v(_vm._s(_vm.afterVslNm))]
                                ),
                              ]),
                              _c("span", { staticClass: "dv col_3" }, [
                                _c(
                                  "span",
                                  { staticClass: "screen-to-pdf-show" },
                                  [_vm._v(_vm._s(_vm.afterVoyNo))]
                                ),
                              ]),
                              _c("span", { staticClass: "dv font_12" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.ONEX020P120.010"))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "normal_box" }, [
                    _c("ol", [
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.011")))]),
                      _c("li", [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.012")))]),
                    ]),
                    _c("p", { staticClass: "text_center" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$ekmtcCommon.changeDatePattern(_vm.nowDate, ".")
                        )
                      ),
                    ]),
                    _c("p", { staticClass: "text_center mt10" }, [
                      _c("span", { staticClass: "screen-to-pdf-hide" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.agreeYn,
                              expression: "agreeYn",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "chk1",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          domProps: {
                            checked: Array.isArray(_vm.agreeYn)
                              ? _vm._i(_vm.agreeYn, null) > -1
                              : _vm._q(_vm.agreeYn, "Y"),
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.agreeYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? "Y" : "N"
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.agreeYn = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.agreeYn = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.agreeYn = $$c
                              }
                            },
                          },
                        }),
                        _vm._m(2),
                      ]),
                      _vm._m(3),
                      _vm._v(
                        " " + _vm._s(_vm.$t("msg.ONEX020P120.013")) + " ("
                      ),
                      _c("span", { staticClass: "ml10 mr10" }, [
                        _vm._v(_vm._s(_vm.bookingInfo.actShprCstEnm)),
                      ]),
                      _vm._v(")" + _vm._s(_vm.$t("msg.ONEX020P120.014")) + " "),
                    ]),
                    _c(
                      "p",
                      {
                        staticClass: "txt_desc mt10",
                        staticStyle: { background: "none" },
                      },
                      [
                        _c("span", { staticClass: "font_medium" }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.023"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.024")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.033"))),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.044")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.047"))),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025_1"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.045")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.048"))),
                        _c("br"),
                        _c("span", { staticStyle: { "margin-left": "34px" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025_2"))),
                        ]),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.046")) + " "
                        ),
                        _c("span", { staticClass: "txt_bar" }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.049")) + " "
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c("div", { staticClass: "content_box" }, [
                  !(
                    _vm.memberDetail.userCtrCd === "KR" &&
                    _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                  )
                    ? _c("p", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.ONEX020P120.017")) + " "
                        ),
                      ])
                    : _vm._e(),
                  _c("p", { staticClass: "mt5" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.memberDetail.userCtrCd !== "KR",
                            expression: "memberDetail.userCtrCd !== 'KR'",
                          },
                        ],
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "button sm",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.excelDownload(
                                  "BookingCancelRequest.xlsx"
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.018")))]
                        ),
                      ]
                    ),
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("span", { staticClass: "ml5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "button sm",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.moveDelegateReqPage()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.019")))]
                          ),
                        ])
                      : _vm._e(),
                    !(
                      _vm.memberDetail.userCtrCd === "KR" &&
                      _vm.$store.getters["auth/getCalcEiCatCd"] === "O"
                    )
                      ? _c("span", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon help ml10",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.guidePop()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.027")))]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("h2", { staticClass: "content_title" }, [
                    _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.020"))),
                  ]),
                  _vm._v(" "),
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(4),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.021"))),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm.memberDetail.userCtrCd == "KR"
                            ? _c(
                                "div",
                                {
                                  staticClass: "filebox",
                                  attrs: { id: "file_att" },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "button sm gray file",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.openEleConsReqDocPop()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("js.booking.043")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass: "filebox",
                                  attrs: { id: "file_att" },
                                },
                                [
                                  _vm.uploadParam.length === 0
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "button sm gray file",
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.uploadFilePop()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("msg.ONEX020P120.022")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.uploadParam.length > 0
                                    ? _c("div", [
                                        _c("span", {
                                          staticClass: "tbl_icon attach",
                                        }),
                                        _c("a", { staticClass: "text_link" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.uploadParam[0].originalName
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "tbl_icon attdel",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeUploadFile()
                                              },
                                            },
                                          },
                                          [_vm._v("X")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "txt_desc mt10",
                      staticStyle: { background: "none" },
                    },
                    [
                      _c("span", { staticClass: "font_medium" }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.023"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.024")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.033"))),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.044")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.047"))),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025_1"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.045")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.048"))),
                      _c("br"),
                      _c("span", { staticStyle: { "margin-left": "34px" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX020P120.025_2"))),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.046")) + " "),
                      _c("span", { staticClass: "txt_bar" }),
                      _vm._v(" " + _vm._s(_vm.$t("msg.ONEX020P120.049")) + " "),
                    ]
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "mt10 text_right screen-to-pdf-hide" }, [
              _c(
                "a",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.memberDetail.userCtrCd !== "KR",
                      expression: "memberDetail.userCtrCd !== 'KR'",
                    },
                  ],
                  staticClass: "button blue lg mr5",
                  on: {
                    click: function ($event) {
                      return _vm.reqBookingSave()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.ONEX020P120.021")))]
              ),
              _vm.delegateYn === "Y"
                ? _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020P030.007")))]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "button gray lg",
                      on: {
                        click: function ($event) {
                          return _vm.confirmClose()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.NEWB020P030.007")))]
                  ),
            ]),
            _vm.delegateYn === "Y"
              ? _c(
                  "win-layer-pop",
                  { staticClass: "popup_dim" },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          attrs: { "parent-info": _vm.popParams },
                          on: {
                            close: _vm.closePopup,
                            selectFunc: _vm.selectFunc,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c(
                  "win-layer-pop",
                  { ref: "uploadPop", staticClass: "popup_dim" },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          attrs: {
                            "parent-info": _vm.uploadParam,
                            "single-file-yn": true,
                          },
                          on: {
                            "set-dext-file-info": _vm.arrangeUploadParam,
                            close: _vm.closePopup,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c(
                  "win-layer-pop",
                  { ref: "openPop", staticClass: "popup_dim_snd" },
                  [
                    _vm.popComponent
                      ? _c(_vm.popComponent, {
                          tag: "component",
                          attrs: { "parent-info": _vm.popParams },
                          on: {
                            close: _vm.closeCommonPopup,
                            callback: _vm.popCallback,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.delegateYn === "N"
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false",
                      },
                    ],
                    attrs: { id: "guide_pop" },
                  },
                  [
                    _vm.customComponent
                      ? _c(_vm.customComponent, {
                          tag: "component",
                          on: { close: _vm.closePopup },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "200" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk1" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "screen-to-pdf-show" }, [
      _c("input", {
        attrs: {
          checked: "checked",
          disabled: "disabled",
          type: "checkbox",
          name: "no",
          id: "chk1_temp",
        },
      }),
      _c("label", { attrs: { for: "chk1_temp" } }, [
        _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { staticStyle: { width: "200px" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }